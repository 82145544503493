import React, { useState, useEffect,useRef } from 'react'
import { CommonHeading } from '../../common/commonHeading';
import { Container, Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";

import '../../assets/styles/common.css'
import '../../assets/styles/responsive.css'
import '../styles/dashboard.css'


import helpIcon from '../../assets/traceplusImages/help-icon.png'
import pinkArrowIcon from '../../assets/traceplusImages/pink_outline_right_arrow_icon.svg'
import selectedPinkArrowIcon from '../../assets/traceplusImages/pink_right_arrow_icon.svg'

import moment from 'moment'
import 'antd/dist/antd.css';
import spinnerLoader from '../../assets/images/Spinner Loader.gif'
import overheating from '../../assets/images/overheating.png'
import {ReactComponent as MircroChip} from '../../assets/images/microchip-solid.svg'
import {ReactComponent as UpIcon} from '../../assets/images/arrow-up-solid.svg'
import {ReactComponent as DownIcon} from '../../assets/images/arrow-down-solid.svg'

import {getOnBoardTag,getProductivitylist,getfunctionList,postCreadonBoard,getReportOnBoard } from '../actionMethods/actionMethods';
import "react-datepicker/dist/react-datepicker.css";
import { logoutUser } from '../../login/actionMethods/actionMethods';          
import axios from 'axios';
import { Select } from 'antd';
import DatePicker from "react-datepicker";
import { AgGridReact,AgGridColumn } from 'ag-grid-react'; // the AG Grid React Component

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Button, Modal , Space } from 'antd';
import Export from './export';
import DashboardStats from './DashboardStats';
import {ReactComponent as Edit} from '../../assets/images/edi.svg'
import {ReactComponent as Clear} from '../../assets/images/clearall.svg'
import WarningSign from '../../assets/images/warning.png'

import GreenFlag from '../../assets/images/greenflag.png'
import RedFlag from '../../assets/images/redflag.png'
import YellowFlag from '../../assets/images/orangeflag.png'
import { getWorkDayTime } from '../actionMethods/actionMethods';
import {translate} from '../../components/translation'



function Dashboard(props) {

  
  let requestBody={};
  let userDetails = localStorage.getItem('userLoginDetailsOnBoard')?JSON.parse(localStorage.getItem('userLoginDetailsOnBoard')):[];
  let lan = localStorage.getItem('userOnBoardLanguage')?localStorage.getItem('userOnBoardLanguage'):'en';

  let sub_org_id =  userDetails.sub_org_id?userDetails.sub_org_id:'';
    const [language,SetLanguage] = useState(lan);
    const [workerID,SetWorkerID]=useState('')
    const [tagID,SetTagID]=useState('')
    const [Function,SetFunction]=useState('')
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [onBoardEmp,SetOnBoardEmp] =useState([])
    const [onBoardEmpDisplay,SetOnBoardEmpDisplay] =useState([])
    const [selectedEmp,SetSelectedEmp] = useState({});
    
    const [workerIDList,SetWorkerIDList]=useState([])
    const [dailyworkerIDList,SetDailyWorkerIDList]=useState([])

    const [workerTag,SetWorkerTag]=useState([])
    const [dailyworkerTag,SetDailyWorkerTag]=useState([])

    const [search,SetSearch]=useState('');
    const [message,SetMessage]=useState('');  
    const [messageErrorWorker,SetMessageErrorWorker]=useState('');  
    const [messageErrorTag,SetMessageErrorTag]=useState('');  
    const [messageErrorFunction,SetMessageErrorFunction]=useState('');  
    const [displayMessage,SetMessageDisplay]=useState(false)
    const [allemp,SetAllEmp] = useState([]);
    const [regularfunction,SetRegularFunction] = useState([]);
    const [dailyfunction,SetDailyFunction] = useState([]);
    const { Option } = Select;
    const [dates, setDates] = useState({
      start: null,
      end: null,
    });
    const elementRef =useRef(null);
    const [widthContainer,SetWidthContainer]=useState(0)
    const [tagAPI,SetTagAPI] =useState(false);
    const [checkInRegular,SetCheckInRegular] =useState(0)
    const [checkInDaily,SetCheckInDaily] =useState(0)
    const [checkOut,SetCheckOut] =useState(0)

    const [modalSearch,SetModalSearch]=useState('');
    const [inc,SetInc] =useState(1);
    const [maxDate,SetMaxDate] =useState(null)
    const [notMessage,SetNoteMessage] =useState('');
    const [modalLogout,SetModalLogout]=useState(false);
    const [searchCheckIn,SetSearchCheckIn]=useState('');
    const [focus,SetFocus] =useState(true);
    const [workingHourDate,SetWorkingHourDate]= useState({
      start: null,
      end: null,
    })
    const [apiEnable,SetAPIEnable]=useState(true);
    const [shiftShortModal,SetShiftShortModal] = useState(false);
    const [shortTime,SetShortTime]= useState('');
    const [nonCheckout,SetNonCheckout] = useState([]);
    const [pastDay,SetPastDay] = useState({start:null,end:null});
    const [dailyScanData,SetDailyScanData] = useState({scan_in:0,scan_out:0})
    const [regularScanData,SetRegularScanData] = useState({scan_in:0,scan_out:0})
    const [indonesiaTime,SetIndonesiaTime] = useState('');
    

    
const handleChange = (value) => {
  console.log(`selected ${value}`);
};

const translateAMPM = (timeFormat,language) => {
  let translatedTime;
  if(language ==='en'){
    return timeFormat
  }
  if (timeFormat.includes('AM')) {
    translatedTime = timeFormat.replace('AM', 'Pagi');
  } else if (timeFormat.includes('PM')) {
    translatedTime = timeFormat.replace('PM', 'Sore/Malam');
  }
  return translatedTime;
};

    useEffect(()=>{
      
     getWorkDayTime(userDetails.session,userDetails.org_id,userDetails.sub_org_id).then(res=>{
      console.log("res",res);
      if(res.status==200){
          let sHour =  res.data.start_hour.split(":");
          let eHour = res.data.end_hour.split(":");
          //same_day
      var date = moment(); 
      var timeAndDate = moment(date).set({"hour": eHour[0], "minute": eHour[1]});
    
      let dt={start:null,end:null}
      
      let message = `Work Day  ${moment().set({hour:sHour[0],minute:sHour[1]}).format("h:mm A")} to ${moment().set({hour:eHour[0],minute:eHour[1]}).format("h:mm A")}`
      
      SetIndonesiaTime(`${translate('Work Day','ind')}  ${translateAMPM(moment().set({hour:sHour[0],minute:sHour[1]}).format("h:mm A"),'ind')} - ${translateAMPM(moment().set({hour:eHour[0],minute:eHour[1]}).format("h:mm A"),language )}`)
      SetNoteMessage(message);


      if(timeAndDate <= moment() ){
        // let message = `Work Day ${moment(date).format('DD')} : ${moment(date).format('DD MMM')} ${moment().set({hour:sHour[0]}).format("hA")} to ${moment(date).add(1,'days').format('DD MMM')} ${moment().set({hour:eHour[0]}).format("hA")}`
        SetPastDay((prev) => ({ ...prev, [`start`]:moment(date).subtract(1,'days').toDate()}))
        SetPastDay((prev) => ({ ...prev, [`end`]:moment(date).subtract(1,'days').toDate()}))

        setDates((prev) => ({ ...prev, [`start`]:moment(date).toDate()  }));
        setDates((prev) => ({ ...prev, [`end`]:  moment(date).add(1,'days').toDate()}));   
        dt.start=moment(date).toDate();
        dt.end=moment(date).add(1,'days').toDate()
        SetWorkingHourDate((prev) => ({ ...prev, [`start`]:moment(date).set({"hour": sHour[0], "minute": sHour[1]})   }));
        SetWorkingHourDate((prev) => ({ ...prev, [`end`]:  moment(date).add(1,'days').set({"hour": eHour[0], "minute": eHour[1],second:59 }) }));
        SetMaxDate(moment(date).add(1,'days').toDate())
      }
      else if(eHour[0]== "23" && eHour[1] == "59"  && sHour[0]=="00" && sHour[1]=="00"){
        SetPastDay((prev) => ({ ...prev, [`start`]:moment(date).subtract(1,'days').toDate()}))
        SetPastDay((prev) => ({ ...prev, [`end`]:moment(date).subtract(1,'days').toDate()}))

        setDates((prev) => ({ ...prev, [`start`]:moment(date).toDate()  }));
        setDates((prev) => ({ ...prev, [`end`]:  moment(date).add(1,'days').toDate()}));   
        dt.start=moment(date).toDate();
        dt.end=moment(date).add(1,'days').toDate()
        SetWorkingHourDate((prev) => ({ ...prev, [`start`]:moment(date).set({"hour": sHour[0], "minute": sHour[1]})   }));
        SetWorkingHourDate((prev) => ({ ...prev, [`end`]:  moment(date).set({"hour": eHour[0], "minute": eHour[1],second:59 }) }));
        SetMaxDate(moment(date).add(1,'days').toDate())
      }
      else {
        // let message = `Work Day ${moment(date).subtract(1,'days').format('DD')} : ${moment(date).subtract(1,'days').format('DD MMM')} ${moment().set({hour:sHour[0]}).format("hA")} to ${moment(date).format('DD MMM')} ${moment().set({hour:eHour[0]}).format("hA")}`
        // SetNoteMessage(message);

        SetPastDay((prev) => ({ ...prev, [`start`]:moment(date).subtract(2,'days').toDate()}))
        SetPastDay((prev) => ({ ...prev, [`end`]:moment(date).subtract(2,'days').toDate()}))

        setDates((prev) => ({ ...prev, [`start`]:moment(date).subtract(1,'days').toDate()  }));
        setDates((prev) => ({ ...prev, [`end`]:  date.toDate()}));

        dt.start= moment(date).subtract(1,'days') ;
        dt.end=moment(date).toDate()


        SetWorkingHourDate((prev) => ({ ...prev, [`start`]:moment(date).subtract(1,'days').set({"hour": sHour[0], "minute": sHour[1]}) }));
        SetWorkingHourDate((prev) => ({ ...prev, [`end`]:  date.set( {"hour": eHour[0], "minute": eHour[1],second:59 } )  }));
        SetMaxDate(moment(date).toDate())
      }



      }
     
      
      





     })
      
     
    },[])


    const showModal = () => {
      setIsModalOpen(true);
    };
  
    const handleOk = () => {
      setIsModalOpen(false);
    };
  
    const handleCancel = () => {
      setIsModalOpen(false);
    };

    useEffect(() => {
    
      if(elementRef && elementRef.current){
        
        SetWidthContainer(elementRef.current.getBoundingClientRect().width);
      }
      
    }, [elementRef]);  
    const option =[
      {label:'demo 1',value:"demo 1"},
      {label:'demo 2',value:"demo 2"},
      {label:'demo 3',value:"demo 3"},
      {label:'demo 4',value:"demo 4"},
      {label:'demo 5',value:"demo 5"},
      {label:'demo 6',value:"demo 6"},
      {label:'demo 7',value:"demo 7"},
    ]
  
  

    useEffect(() => {

        if (props.match.path == '/') {
            props.history.push('/dashboard')
        }

        getOnBoardTag(userDetails.session,userDetails.org_id,sub_org_id).then(res=>{
          if(res.status==200){
            SetWorkerIDList(res.data.regular_worker_ids);
            SetDailyWorkerIDList(res.data.daily_worker_ids);
            SetDailyWorkerTag(res.data.daily_worker_tags);
            SetWorkerTag(res.data.regular_worker_tags);
            SetTagAPI(true);
          }
        })

        getfunctionList(userDetails.session,userDetails.org_id,sub_org_id).then(res=>{
          if(res.status==200){
            //SetRegularFunction(res.data)
            SetDailyFunction(res.data)
          }
        })
    }, []);

    function checkStatus (currentJakartaTime,localTime,agency="",user){
      const timeDifferenceMinutes = Math.abs(currentJakartaTime.diff(localTime, 'minutes'));

        if (timeDifferenceMinutes >= 840) {

          return "orange"
        }else if(timeDifferenceMinutes >= 480 &&( parseInt(userDetails.org_id)=== 33 || agency.toLowerCase() ==='lion parcel')){

          return "green"
        }
        else if(timeDifferenceMinutes >= 540 && timeDifferenceMinutes < 840 ){ 

          return "green"
        }else{

          return "red"
        }


      return ;
    }

    useEffect(()=>{
      let requestBody={};
      requestBody.startdate= moment(dates.start).format('YYYY-MM-DD');
      requestBody.enddate= moment(dates.end).format('YYYY-MM-DD');
      if(tagAPI){  


        
        getReportOnBoard(requestBody,userDetails.session,userDetails.org_id,sub_org_id).then(res=>{
          if(res.status==200){
            let checkInReg =  0
            let checkInDail =  0
            let ckOutDaily=     0
            let ckOutRg=     0

            let emp = []
            if(res.data && res.data.daily){
              //res.data.daily =res.data.daily.filter(item=>moment.utc(item.check_in) > moment(workingHourDate.start).set({"hour": 7, "minute": 0}) && moment.utc(item.check_in) < moment(workingHourDate.end).set({"hour": 6, "minute": 0}) )
             
             
              res.data.daily.map(item=>{
                let el=item;
                item.type="Daily Worker"
                if(dailyworkerIDList.find(item=>item.worker_id == item.worker_id)){
                   el.department= item.department_name;
                   el.function=item.sub_department_name   
                   el.check_in= moment.utc(item.check_in);
                   el.check_out=item.check_out==""||item.check_out==null?"-":moment.utc(item.check_out);
                   

                  if(el.check_out && el.check_out!="-"){

                      const localTime = moment(new Date(item.check_in._i));
                      const currentJakartaTime = moment(new Date(item.check_out._i));
                      el.status = checkStatus(localTime,currentJakartaTime,el.agency,el);
                  }else{
                    el.status = "-"
                  }


                }else{
                  el.department=item.department_name;
                  el.function=item.sub_department_name;   
                  el.check_in= moment.utc(item.check_in);
                  el.check_out=item.check_out==""||item.check_out==null?"-":moment.utc(item.check_out);
                  if(el.check_out && el.check_out!= "-"){
                    const localTime = moment(new Date(item.check_in._i));
                      const currentJakartaTime = moment(new Date(item.check_out._i));
                    el.status = checkStatus(localTime,currentJakartaTime,el.agency,el);
                  }else{
                    el.status = "-"
                  }
                }
                return el 
              })
              emp.push(...res.data.daily)
              checkInDail =  res.data.daily.filter(item=>item.check_in && item.check_out=='-').length;    
              ckOutDaily=     res.data.daily.filter(item=>item.check_in && item.check_out!='-').length;   
  
            }
            
            if(res.data && res.data.regular){  
              //res.data.regular =res.data.regular.filter(item=>moment.utc(item.check_in) > moment(dates.start).set({"hour": 7, "minute": 0}) && moment.utc(item.check_in) < moment(dates.end).set({"hour": 6, "minute": 0}) )
              res.data.regular.map(item=>{
                let el=item;
                item.type="Regular Worker"
                

                if(workerIDList.find(item=>item.worker_id == item.worker_id)){
                  el.check_in= moment.utc(item.check_in);
                    el.department= item.department_name;
                   el.function=item.sub_department_name
                  el.check_out=item.check_out==""||item.check_out==null?"-":moment.utc(item.check_out);

                  if(el.check_out && el.check_out!="-"){
                    const localTime = moment(new Date(item.check_in._i));
                    const currentJakartaTime = moment(new Date(item.check_out._i));
                    
                    el.status = checkStatus(localTime,currentJakartaTime,el.agency,el);
                  }else{
                    el.status = "-"
                  }


                }else{
                  el.department=item.department_name;
                  el.function=item.sub_department_name
                  el.check_in= moment.utc(item.check_in);
                  // el.check_out= "-";
                   el.check_out=item.check_out==""||item.check_out==null?"-":moment.utc(item.check_out);
                  
                   if(el.check_out && el.check_out!="-"){
                    const localTime = moment(new Date(item.check_in._i));
                    const currentJakartaTime = moment(new Date(item.check_out._i));
                    
                    el.status = checkStatus(localTime,currentJakartaTime,el.agency,el);
                  }else{
                    el.status = "-"
                  }

                   
                }
                return el 
              })
              ckOutRg=     res.data.regular.filter(item=>item.check_in && item.check_out!='-').length;   
              checkInReg =  res.data.regular.filter(item=>item.check_in && item.check_out=='-').length;            
  
              emp.push(...res.data.regular)
            }
           
            let checDataReg  =emp.filter(el=>el.type =="Regular Worker");
            //  "-"
            let regData = {
              scan_in:checDataReg.filter(el=>el.check_out=="-").length,
              scan_out:checDataReg.filter(el=>el.check_out!="-").length
             };

            let checDataDaily  =emp.filter(el=>el.type =="Daily Worker");
            //  "-"
            let dailyData = {
              scan_in:checDataDaily.filter(el=>el.check_out=="-").length,
              scan_out:checDataDaily.filter(el=>el.check_out!="-").length
            };

            SetDailyScanData(dailyData);
            SetRegularScanData(regData);

            SetCheckInDaily(checkInDail);
            SetCheckInRegular(checkInReg);
            SetCheckOut(ckOutRg+ckOutDaily);         
            SetOnBoardEmp(emp);
            SetOnBoardEmpDisplay(emp);
          }
        })
      }
     },[dates,allemp,tagAPI,inc])


         useEffect(()=>{
          
      let requestBody={};
      requestBody.startdate= moment(pastDay.start).format('YYYY-MM-DD');
      requestBody.enddate= moment(pastDay.end).format('YYYY-MM-DD');

      if(tagAPI){  



        
        getReportOnBoard(requestBody,userDetails.session,userDetails.org_id,sub_org_id).then(res=>{
          if(res.status==200){

            let emp = []
            if(res.data.daily){
              res.data.daily = res.data.daily.filter(el=>el.check_out ===null)
            }

            if(res.data.regular){
              res.data.regular = res.data.regular.filter(el=>el.check_out ===null)
            }
            
            if(res.data && res.data.daily){
              //res.data.daily =res.data.daily.filter(item=>moment.utc(item.check_in) > moment(workingHourDate.start).set({"hour": 7, "minute": 0}) && moment.utc(item.check_in) < moment(workingHourDate.end).set({"hour": 6, "minute": 0}) )             
             
              res.data.daily.map(item=>{
                let el=item;
                item.type="Daily Worker"
                if(dailyworkerIDList.find(item=>item.worker_id == item.worker_id)){
                   el.department= item.department_name;
                   el.function=item.sub_department_name   
                   el.check_in= moment.utc(item.check_in);
                   el.check_out=item.check_out==""||item.check_out==null?"-":moment.utc(item.check_out);
                }else{
                  el.department=item.department_name;
                  el.function=item.sub_department_name;   
                  el.check_in= moment.utc(item.check_in);
                  el.check_out=item.check_out==""||item.check_out==null?"-":moment.utc(item.check_out);
                 
                }
                return el 
              })
              emp.push(...res.data.daily)
  
            }
            
            if(res.data&&res.data.regular){  
              //res.data.regular =res.data.regular.filter(item=>moment.utc(item.check_in) > moment(dates.start).set({"hour": 7, "minute": 0}) && moment.utc(item.check_in) < moment(dates.end).set({"hour": 6, "minute": 0}) )
              res.data.regular.map(item=>{
                let el=item;
                item.type="Regular Worker"
                
                if(workerIDList.find(item=>item.worker_id == item.worker_id)){
                  el.check_in= moment.utc(item.check_in);
                  el.department= item.department_name;
                  el.function=item.sub_department_name
                  el.check_out=item.check_out==""||item.check_out==null?"-":moment.utc(item.check_out);
                }else{
                  el.department=item.department_name;
                  el.function=item.sub_department_name
                  el.check_in= moment.utc(item.check_in);
                  el.check_out=item.check_out==""||item.check_out==null?"-":moment.utc(item.check_out);
                }
                return el 
              })
  
              emp.push(...res.data.regular)
            }

            SetNonCheckout(emp)
          }
        })
      }
     },[pastDay,allemp,tagAPI,inc])






     useEffect(()=>{
      
      if(dates.start!=null && dates.end!=null){
      let requestBody={};
      requestBody.startdate= moment(dates.start).format('YYYY-MM-DD');
      requestBody.enddate= moment(dates.end).format('YYYY-MM-DD');
      requestBody.slug= 'employee';
      let arr=[]
      getProductivitylist(requestBody,userDetails.session,userDetails.org_id,sub_org_id).then(res=>{
        if(res.data && res.status==200){
          let catIndex=0;
          let empList= [];
          // Object.keys(res.data).forEach((key) => {
            
          //   let element=res.data[key];
          //   let child= [];
          //   let childName='';
          //   let prod = 0;
          //   let nonprod = 0;
          //   let lessprod = 0;
          //   let und = 0;
          //   let acs = 0;
  
  
  
          //   let parentLength=0;
          //   Object.keys(element).forEach((key2) => {
          //     if(isInt(key2)){
          //       prod = prod + element[key2].productive;
          //       nonprod= nonprod+element[key2].non_productive;
          //       lessprod = lessprod+element[key2].less_productive;
          //       und = und +element[key2].undefined;
          //       acs = acs + element[key2].access_policy_breach
                
          //     }else{
                
          //       childName=key2
          //       let prodCat = 0;
          //       let nonprodCat = 0;
          //       let lessprodCat = 0;
          //       let undCat = 0;
          //       let acsCat = 0;
  
  
          //       for (let c = 0;c<element[key2].length;c++){
          //         parentLength++;
          //         prodCat=prodCat+element[key2][c].productive;
          //         nonprodCat=nonprodCat+element[key2][c].non_productive;
          //         lessprodCat=lessprodCat+element[key2][c].less_productive;
          //         undCat= undCat + element[key2][c].undefined;
          //         acsCat= acsCat + element[key2][c].access_policy_breach;
  
          //         prod = prod + element[key2][c].productive;
          //         nonprod= nonprod+element[key2][c].non_productive;
          //         lessprod = lessprod+element[key2][c].less_productive;
          //         und = und +element[key2][c].undefined;
          //         acs = acs + element[key2][c].access_policy_breach;
  
          //         element[key2][c].type='employee'
          //         element[key2][c].department=key;
          //         element[key2][c].sub_department=key2;
          //         empList.push(element[key2][c]);
  
          //       }
          //       child.push({name:key2,data:element[key2],productive:parseFloat(prodCat/element[key2].length).toFixed(2),non_productive:parseFloat(nonprodCat/element[key2].length).toFixed(2),less_productive:parseFloat(lessprodCat/element[key2].length).toFixed(2),undefined:parseFloat(undCat/element[key2].length).toFixed(2) ,access_policy_breach:parseFloat(acsCat/element[key2].length).toFixed(2) })
          //     }
  
          //   });
  
            
              
          //  });
          
           SetAllEmp(res.data);             
           

          }
          if(res=="Session expired"){
            SetModalLogout(true);          
          }
      })
    }
     },[dates])
    const handleDate = (date, type) => {
      setDates((prev) => ({ ...prev, [`${type}`]: date }));
      let startDate = dates.start
      let endDate = dates.end
      if(type=="start"){
        startDate = date
        let message = `${translate('Work Day',language)} ${moment(startDate).format('DD')} : ${moment(startDate).format('DD MMM')} 7AM to ${moment(endDate).format('DD MMM')} 6AM`
        //SetNoteMessage(message)
      }else{
        endDate = date
        let message = `${translate('Work Day',language)} ${moment(startDate).format('DD')} : ${moment(startDate).format('DD MMM')} 7AM to ${moment(endDate).format('DD MMM')} 6AM`
        //SetNoteMessage(message)
      }

    };
    function isInt(value) {
      return !isNaN(value) && (function(x) { return (x | 0) === x; })(parseFloat(value))
    }

function searchHandler(val){
val = val.trim();

  if(workingHourDate.start && workingHourDate.end){
  
  if(isModalOpen){
    
  }else{
    SetSearch(val)
  }
  
  let emp= selectedEmp?selectedEmp:{};
  let res= false;

  if(val.length>0){
    let checkTag ='';
    
    if(val.startsWith("01")){
      checkTag =  val.includes(":")?val.replaceAll(":", "").toUpperCase():val.toUpperCase() ;
    }else{
      checkTag =  val.includes(":")? "01"+val.replaceAll(":", "").toUpperCase():"01"+val.toUpperCase() ;
    }



  if(workerIDList.find(item => item.worker_id ==  val.replaceAll(":", ""))){
  //  console.log("Here execute 2",val);

    let wk = workerIDList.find(item => item.worker_id ==  val.replace(":", ""))
    // let checkCond = [...onBoardEmp].find(item=>item.worker_id== val.replace(":", "")  && item.check_in!=null && (item.check_out!=null && item.check_out!='-')  && (item.check_in > workingHourDate.start && item.check_out < workingHourDate.end)  )
    let checkCond = [...onBoardEmp].find(item=>item.worker_id== val.replace(":", "")  && item.check_in!=null && (item.check_out!=null && item.check_out!='-')  && (new Date(`${moment(item.check_in).format('YYYY-MM-DD HH:mm')}`)  > new Date(`${moment(workingHourDate.start).format('YYYY-MM-DD HH:mm')}`) && new Date(`${moment(item.check_out).format('YYYY-MM-DD HH:mm')}`)  < new Date(`${moment(workingHourDate.end).format('YYYY-MM-DD HH:mm')}`))  )
    
    SetSearch('');
    if(checkCond){
      toast.error( "TP:07 "+translate("Invalid :- Tag used for the day",language));
    }else if(emp.tag_serial && dailyworkerTag.indexOf(emp.tag_serial) > -1 ){
      toast.error("TP:08 +"+translate("Scanned tag not belong to regular worker",language))
    }else{
  
    let rgTag= [...allemp].find(item=>item.worker_id == val.replace(":", ""))
    if(rgTag){      
      let emp={}
      emp.type='Regular Worker'
      emp.worker_type= 'regular'      
      emp.worker_id= wk.worker_id
      emp.tag_serial= rgTag.tag_serial
      emp.department= wk.department
      emp.agency= wk.agency?wk.agency:""
      emp.name= wk.name

      SetWorkerID(wk.worker_id);
      SetTagID(rgTag.tag_serial);
      SetSelectedEmp(emp);
      if(isModalOpen){      setIsModalOpen(false);SetModalSearch("")}

      toast.success('TP:09 '+translate('Valid Regular Worker ID',language))
    }
    
  
    res=true;
    
  }


  }
//Regular tag serial  
//E5:F4:04:21:AC:8F

  else if(workerTag.indexOf(checkTag.replaceAll(":", ""))> -1){
    SetSearch('')
    //console.log("Here execute 3",val);

    res=true;
    let tagSelect= workerTag.indexOf(checkTag.replaceAll(":", "").toUpperCase())

    let rgTag= [...allemp].find(item=>item.tag_serial == workerTag[tagSelect])
    let checkCond = [...onBoardEmp].find(item=>item.tag_serial== workerTag[tagSelect]  && item.check_in!=null && (item.check_out!=null && item.check_out!='-') &&       ( new Date(`${moment(item.check_in).format('YYYY-MM-DD HH:mm')}`) > new Date(`${moment(workingHourDate.start).format('YYYY-MM-DD HH:mm')}`) && new Date(`${moment(item.check_out).format('YYYY-MM-DD HH:mm')}`) < new Date(`${moment(workingHourDate.end).format('YYYY-MM-DD HH:mm')}`) ))

    SetSearch('');
    if(checkCond){
      toast.error(translate("TP:10 Invalid :- Tag used for the day",language));
    }else{
      if(rgTag){
        SetTagID(workerTag[tagSelect])
        SetWorkerID(rgTag.worker_id)
        let emp={}
        emp.type='Regular Worker'
        emp.worker_type= 'regular'
        let agency =workerIDList.find(e=>e.worker_id== checkTag.replaceAll(":", "") )?.agency

        emp.worker_id= rgTag.worker_id
        emp.tag_serial= rgTag.tag_serial
        emp.department= rgTag.department
        emp.name= rgTag.name
        emp.agency=workerIDList.find(e=>e.worker_id == rgTag.worker_id)?.agency
        emp.tag_serial=workerTag[tagSelect];
        emp.worker_id= rgTag.worker_id

        
        SetSelectedEmp(emp);      
  
        toast.success('TP:11 '+translate('Valid Regular Worker Tag ID',language));
       
  
        if(isModalOpen){  
              setIsModalOpen(false)
          }
  
          if(isModalOpen){      setIsModalOpen(false);SetModalSearch("")}  
      }else if(emp.worker_id &&   dailyworkerIDList.findIndex(item=>item.worker_id == emp.worker_id)  > -1){
        toast.error("TP:12 "+translate("Scanned tag not belong to regular worker",language))
        
      }
      
  

      
    }

    


  }
    //daily Worker Id Regular
  else if(dailyworkerIDList.find(item=> item.worker_id == val.replaceAll(":", ""))){
//    console.log("Here execute 4",val);


    let checkCond = [...onBoardEmp].find(
      item=>item.worker_id ==  val.replace(":", "")  &&
      item.check_in!=null && new Date(`${moment(item.check_in).format('YYYY-MM-DD HH:mm')}`) > new Date(`${moment(workingHourDate.start).format('YYYY-MM-DD HH:mm')}`) && (item.check_out!=null &&
      item.check_out!='-' 
    ))

    let checkCheckinData= [...onBoardEmp].find(
        item=>item.worker_id ==  val.replace(":", "")  && 
        item.check_in!=null &&
        (item.check_out!=null && 
        (item.check_out==null || item.check_out=='-' ) && (new Date(`${moment(item.check_in).format('YYYY-MM-DD HH:mm')}`) > new Date(`${moment(workingHourDate.start).format('YYYY-MM-DD HH:mm')}`) && new Date(`${moment(item.check_out).format('YYYY-MM-DD HH:mm')}`) < new Date(`${moment(workingHourDate.end).format('YYYY-MM-DD HH:mm')}`)) )  
        );
       
    SetSearch('');
    if(checkCond){
      toast.error("TP:13 "+translate("Invalid :- Tag used for the day",language));
    }
    else if(checkCheckinData){
      
      //mapping Daily worker
        let emp={}
        emp.type='Daily Worker'
        emp.worker_type= 'daily'
        emp.worker_id= checkCheckinData.worker_id
        emp.tag_serial= checkCheckinData.tag_serial
        emp.department= checkCheckinData.department?checkCheckinData.department:"-"
        emp.name= checkCheckinData.name  
        emp.agency = dailyworkerIDList.find(item=> item.worker_id == val.replaceAll(":", ""))?.agency



        //here function required.
        SetSelectedEmp(emp);     
        SetTagID(checkCheckinData.tag_serial) 
        SetWorkerID(checkCheckinData.worker_id) 
        
        toast.success('TP:20 '+translate('Valid Daily Worker ID',language));
        if(isModalOpen){      setIsModalOpen(false);SetModalSearch("")}
    }
    else if(emp.tag_serial  &&  workerTag.indexOf(emp.tag_serial)>  -1){
      toast.error("TP:14 "+translate("Invaild ID scanned, Please clear and scan again",language))
    }else{
      emp.type='Daily Worker'
      emp.worker_type= 'daily'
      let wk =dailyworkerIDList.find(item => item.worker_id ==  val.replace(":", ""))
      emp.worker_id= wk.worker_id
      emp.department= wk.department
      emp.name= wk.name
      emp.agency = wk.agency?wk.agency:""
      let funcDetail =  dailyfunction.find(el=>el.category_name === wk.department)
      if(wk.department_id){
        SetFunction(parseInt(wk.department_id));
      }

      let tagS = onBoardEmp.find(item=>item.worker_id == val.replace(":", "") && new Date(`${moment(item.check_in).format('YYYY-MM-DD HH:mm')}`) > new Date(`${moment(workingHourDate.start).format('YYYY-MM-DD HH:mm')}`) );
     
      if(tagS){
        SetTagID(tagS.tag_serial);
      }else if(wk.tag_serial){
        emp.tag_serial = wk.tag_serial
        SetTagID(wk.tag_serial);
        
      }

      SetWorkerID(wk.worker_id)
      SetSearch('')
      SetSelectedEmp(emp);
      if(isModalOpen){      setIsModalOpen(false);SetModalSearch("")}
         toast.success('TP:15 '+translate('Valid Daily Worker ID',language))
    }

    res=true;
  }
    //daily tag serial
  else if(dailyworkerTag.indexOf(checkTag.replaceAll(":", ""))> -1){
    let tagSelect= dailyworkerTag.indexOf(checkTag.replaceAll(":", ""))
    SetSearch('')
   // console.log("Here execute 5",val);
    let checkCond = [...onBoardEmp].find(item=>item.tag_serial ==  dailyworkerTag[tagSelect]  && item.check_in!=null && (item.check_out!=null && item.check_out!='-') &&  (new Date(`${moment(item.check_in).format('YYYY-MM-DD HH:mm')}`) > new Date(`${moment(workingHourDate.start).format('YYYY-MM-DD HH:mm')}`) && new Date(`${moment(item.check_out).format('YYYY-MM-DD HH:mm')}`) < new Date(`${moment(workingHourDate.end).format('YYYY-MM-DD HH:mm')}`)))
    let checkCheckinData = [...onBoardEmp].find(item=>item.tag_serial ==  dailyworkerTag[tagSelect]  && item.check_in!=null && new Date(`${moment(item.check_in).format('YYYY-MM-DD HH:mm')}`) > new Date(`${moment(workingHourDate.start).format('YYYY-MM-DD HH:mm')}`) && (item.check_out==null || item.check_out=='-')  )
    
    SetSearch('');
    if(checkCond){
      toast.error("TP:16 "+translate("Invalid :- Tag used for the day",language));
    }else if(checkCheckinData){
      let emp={}
        emp.type='Daily Worker'
        emp.worker_type= 'daily'
        emp.worker_id= checkCheckinData.worker_id
        emp.tag_serial= checkCheckinData.tag_serial
        emp.department= checkCheckinData.department?checkCheckinData.department:"-"
        emp.name= checkCheckinData.name 
        emp.agency = dailyworkerIDList.find(item=> item.worker_id == checkCheckinData.worker_id)?.agency  
        
      

        SetSelectedEmp(emp);      
        SetTagID(checkCheckinData.tag_serial) 
        SetWorkerID(checkCheckinData.worker_id)
        toast.success('TP:21 '+translate('Valid Daily Worker ID',language));
        if(isModalOpen){      setIsModalOpen(false);SetModalSearch("")}
    }

    else if(emp.worker_id && workerIDList.findIndex(item=> item.worker_id == emp.worker_id) > -1){
      toast.error("TP:17 "+translate("Scanned tag not belong to daily worker",language))
    }else{
      
      emp.tag_serial=dailyworkerTag[tagSelect]
      emp.type='Daily Worker'
      emp.worker_type= 'daily'


      let tagS = onBoardEmp.find(item=>item.tag_serial == checkTag.replaceAll(":", "") && new Date(`${moment(item.check_in).format('YYYY-MM-DD HH:mm')}`) > new Date(`${moment(workingHourDate.start).format('YYYY-MM-DD HH:mm')}`));
      let idOnList = dailyworkerIDList.find(item=> item.tag_serial == dailyworkerTag[tagSelect]);


     
      if(tagS){
        SetWorkerID(tagS.worker_id);
        emp.name=tagS.name
        emp.department=tagS.department
      }else if(idOnList){
        SetWorkerID(idOnList.worker_id);
                emp.name=idOnList.name	
           emp.department=idOnList.department
           emp.agency	= idOnList.agency	

        if(idOnList.department_id){
            SetFunction(parseInt(idOnList.department_id))
        }
        emp.worker_id= idOnList.worker_id; 
      }
      SetSelectedEmp(emp);
      SetTagID(dailyworkerTag[tagSelect])


      if(isModalOpen){      setIsModalOpen(false);SetModalSearch("")}
      toast.success('TP:18 '+translate('Valid Daily Worker Tag ID'))
      
    }
    res=true;
  }else{
   //console.log("Here execute 6",val);
    toast.error('TP:19 '+translate('Invalid',language)+' - '+val);
    SetSearch('')
    res=true;
  }
  SetMessageDisplay(true)

}
}
}


useEffect(()=>{
  
  if(!moment(workingHourDate.end).isValid()){
    
    return 
  }
   const targetDate = new Date(`${moment(workingHourDate.end).format('YYYY-MM-DD HH:mm:ss')}`);
  //  const targetDate = new Date(`2024-07-11 20:42:53`);
    
    const checkTime = () => {
      const now = new Date();

      if (now >= targetDate) {
        
       window.location.reload();

      }else{
       console.log("in  else targetDate",now,targetDate,`${moment(workingHourDate.start).format('YYYY-MM-DD HH:mm:ss')}`)
      }
    };

    const intervalId = setInterval(checkTime, 20000);

    return () => clearInterval(intervalId);
                
    
},[workingHourDate])





    const colorPicker = (percent)=>{
      if(percent<25.1){
        return '#28a745'
      }
      else if(percent<50.1){
        return '#e8ad41'
      }else if(percent<75.1){
        return '#dc3545'
      }else{
        return 'purple'
      }
       
    }



    function checkoutForce(){
      let emp = {...selectedEmp};

            emp.function=Function;
      emp.check_in=moment();
      emp.function= dailyfunction.find(item=>item.category_id == Function )?.category_name
    
      
      let requestBody = {}          
      requestBody.session = userDetails.session
      requestBody.worker_id = workerID
      requestBody.tag_serial = tagID
      requestBody.category_id = Function
      requestBody.org_id = userDetails.org_id
      requestBody.sub_org_id = userDetails.sub_org_id
    
      SetAPIEnable(false);
      console.log("selectedEmp",selectedEmp);



     postCreadonBoard(requestBody).then(res=>{
      //SetAPIEnable(true);
      setTimeout(function(){
        SetAPIEnable(true);
      },2000)
      if(res.status==200){
        SetShiftShortModal(false);
        if(res.data=="Checkout"){
          toast.success("TP:04 "+translate("Check-Out Sucess",language));  

          let allCheckOut = checkOut+1
            SetCheckOut(allCheckOut) 
            
            
            if(emp.type=='Regular Worker'){
              let regularCheckin=checkInRegular>0?checkInRegular - 1:0;        
              SetCheckInRegular(regularCheckin) 

            }else{
              let dailyCheckin=checkInDaily>0?checkInDaily - 1:0;        
              SetCheckInDaily(dailyCheckin) 

            }

        }else if(res.data=="Checkin"){
          toast.success("TP:05 "+translate("Check-In Sucess",language));          
          if(emp.type=='Regular Worker'){
                  let regularCheckin= checkInRegular + 1;     
                  SetCheckInRegular(regularCheckin) 
      
                }else{
                  let dailyCheckin=checkInDaily + 1;        
                  SetCheckInDaily(dailyCheckin) 
      
                }
        }
        else{
          toast.error("TP:06 "+translate("Invalid :- Tag used for the day",language))
        }

  
          SetMessageErrorTag("")
          SetMessageErrorWorker('')
          SetMessageErrorFunction("")
      
          SetMessageDisplay(true);
          setTimeout(function(){
            SetMessageDisplay(false);
          },1000)
          SetWorkerID('');
          SetSearch('');
          SetTagID('');
          SetFunction('');
          SetSelectedEmp({})
    
          myFocusFunction('','')

          SetInc((prev)=>prev +1);
      }else if (res.status==302){
        Modal.error({
          title: translate('Fail to Onboard',language),
          content: translate('Something Went Wrong, Please try again',language),
        });

      }else{
        Modal.error({
          title: translate('Fail to Onboard',language),
          content: translate(res.message,language)?translate(res.message,language):translate('Something Went Wrong',language),
        });
      }
     }).catch(err=>{
      setTimeout(function(){
        SetAPIEnable(true);
      },2000)

     })

    }
    

function Addhandler(){
  
  if(selectedEmp){
    if(tagID==''){
      //SetMessageErrorWorker("Trace+ ID is required")
      toast.error("TP:01 "+translate("Trace+ ID is required",language));
    }else if(workerID==''){
      SetMessageErrorWorker("")
      //SetMessageErrorTag("Worker ID is required")
      toast.error("TP:02 "+translate("Worker ID is required",language));
    }else if(Function=='' && selectedEmp=="Daily Worker"){
      SetMessageErrorTag("")
      toast.error("TP:03 "+translate("Function is required",language));
      // SetMessageErrorFunction("Function is required");
    }else{
      let emp = {...selectedEmp};
      

      let allempBoard=[...onBoardEmp];

      let checkInUser = onBoardEmp.find(item=>(item.worker_id == selectedEmp.worker_id || item.tag_serial== selectedEmp.tag_serial)  && new Date(`${moment(item.check_in).format('YYYY-MM-DD HH:mm')}`) > new Date(`${moment(workingHourDate.start).format('YYYY-MM-DD HH:mm')}`) &&  ( item.check_out!='') );
      let checkoutProper = true;
    
      let condition="check_in";

      if(checkInUser){

      condition="check_out"; 

      const localTime = moment(new Date(checkInUser.check_in._i));
      const currentJakartaTime = moment(new Date(new Date().toLocaleString('en-US', { timeZone: 'Asia/Jakarta' })));


      const timeDifferenceMinutes = Math.abs(currentJakartaTime.diff(localTime, 'minutes'));
      const hours = Math.floor((540 - timeDifferenceMinutes) / 60);
      const minutes = Math.floor((540 - timeDifferenceMinutes) % 60);

      const formattedDifference = `${String(hours).padStart(2, '0')} hr:${String(minutes).padStart(2, '0')} min`;

        if (timeDifferenceMinutes > 540) {

        } else {
          checkoutProper =false;
          SetShortTime(formattedDifference);
           SetShiftShortModal(true);
        }
      }

      
      if(!checkoutProper){
      
      }
      else{

      emp.function=Function;
      emp.check_in=moment();
      emp.function= dailyfunction.find(item=>item.category_id == Function )?.category_name
    
      
      let requestBody = {}          
      requestBody.session = userDetails.session
      requestBody.worker_id = workerID
      requestBody.tag_serial = tagID
      requestBody.category_id = Function
      requestBody.org_id = userDetails.org_id
      requestBody.sub_org_id = userDetails.sub_org_id
    
      SetAPIEnable(false);
      


     postCreadonBoard(requestBody).then(res=>{
      //SetAPIEnable(true);
      setTimeout(function(){
        SetAPIEnable(true);
      },2000)
      if(res.status==200){

        if(res.data=="Checkout"){
          toast.success("TP:04 "+translate("Check-Out Sucess",language));  
          let allCheckOut = checkOut+1
            SetCheckOut(allCheckOut) 
            
            
            if(emp.type=='Regular Worker'){
              let regularCheckin=checkInRegular>0?checkInRegular - 1:0;        
              SetCheckInRegular(regularCheckin) 

            }else{
              let dailyCheckin=checkInDaily>0?checkInDaily - 1:0;        
              SetCheckInDaily(dailyCheckin) 

            }

        }else if(res.data=="Checkin"){
          toast.success("TP:05 "+translate("Check-In Sucess",language));          
          if(emp.type=='Regular Worker'){
                  let regularCheckin= checkInRegular + 1;     
                  SetCheckInRegular(regularCheckin) 
      
                }else{
                  let dailyCheckin=checkInDaily + 1;        
                  SetCheckInDaily(dailyCheckin) 
      
                }
        }
        else{
          toast.error("TP:06 "+translate("Invalid :- Tag used for the day",language))
        }

  
          SetMessageErrorTag("")
          SetMessageErrorWorker('')
          SetMessageErrorFunction("")
      
          SetMessageDisplay(true);
          setTimeout(function(){
            SetMessageDisplay(false);
          },1000)
          SetWorkerID('');
          SetSearch('');
          SetTagID('');
          SetFunction('');
          SetSelectedEmp({})
    
          myFocusFunction('','')

          SetInc((prev)=>prev +1);
      }else if (res.status==302){
        Modal.error({
          title: translate('Fail to Onboard',language),
          content: translate('Something Went Wrong, Please try again',language),
        });

      }else{
        Modal.error({
          title: translate('Fail to Onboard'),
          content: translate(res.message?res.message:'Something Went Wrong',language),
        });
      }
     }).catch(err=>{
      setTimeout(function(){
        SetAPIEnable(true);
      },2000)

     })

 
     
      }
      
    
    }
  }

}

function AGGridEMptyMEssage(key){
  let msg;
      msg='<span class="ag-overlay-no-rows-center">No Records Found !</span>'
  return msg
}


const onCellClicked = (params) =>{

      if (window.getSelection) {
          SetFocus(false);
          
      }else{
 setTimeout(function(){
    SetFocus(true);
  },3000)

      }
 // console.log('Cell was clicked',params.data);

//   var copyText = document.getElementById("myInput");

//   // Select the text field
//   let txt='';

//   // Copy the text inside the text field
//   for(let i =0 ;i<onBoardEmpDisplay.length;i++){
//   txt+=moment(onBoardEmpDisplay[i].check_in).format('DD MMM YYYY')+"\t"+onBoardEmpDisplay[i].name+"\t \n";
// }
// console.log('copied to clipboard',txt)
// navigator.clipboard.writeText(txt);
} 

function newRanderer(value,i){
  const eGui = document.createElement('div');
  let imgSrc;
  let alt="";

  switch (value) {
    case "green":
      imgSrc = GreenFlag;
      alt="Complied"
      break;
    case "orange":
      imgSrc = YellowFlag;
      alt="Non checkout "
      break;
    case "red":
      imgSrc = RedFlag;
      alt="Short time"
      break;
    default:
      imgSrc = ''; // Or some default image or empty string
  }
  if(imgSrc){
    eGui.innerHTML = `<img src="${imgSrc}" alt="${alt}" title="${alt}" width="20px" />`;
  }
  
  return eGui;
}


function AGTable(data){
    

  if(data ){
    
    let widthCal= (widthContainer-170)/9;
    
    let arr;

    data = data.sort((a,b) => {
      let aMs = moment(a.check_in).format('x')
      let bMs = moment(b.check_in).format('x')

      return  bMs - aMs 
    })

    arr=<div className={"keyAGTable"}><div className="ag-theme-alpine if cell-size-40" style={{height: 60 + ((data.length > 0? data.length:1)*36),maxHeight:500 , width: "calc(100% - 1px)"}}>
      <AgGridReact
          rowHeight={35}
          onCellClicked={onCellClicked}
          enableCellTextSelection={true}
//onCellClicked={onCellClicked}
        
          autoGroupColumnDef={{
              headerName: 'Date',minWidth: 200,field: 'name',
  
          }}
          headerHeight={35}

       defaultColDef={{
        sortable: true,resizable: true,
        minWidth:45, 
        width:widthCal , 
        suppressColumnVirtualisation:true,
        skipHeaderOnAutoSize:true,
        
        
        
      }}           
          //pagination={true}
          rowData={data}
          key={'keyAGTable'}
    
          overlayNoRowsTemplate={
              AGGridEMptyMEssage('')
            }        
       >
        
      <AgGridColumn
          field="check_in"
          headerName={"Date"}
          valueFormatter={(params)=>moment(params.value).format('DD MMM YYYY')}
          cellClass={'textCapitalize textCap'}

         // width={75}
      />
        <AgGridColumn
          field="name"
          headerName={translate("Name",language)}
          valueFormatter={(params)=>params.value.toLowerCase()}
          cellClass={'textCapitalize textCap'}
          
      />
        <AgGridColumn
          field="worker_id"
          headerName={translate("Worker ID",language)}
          valueFormatter={(params)=>params.value?params.value:"-"}
          cellClass={'textCapitalize textCap'}
        
      />
      <AgGridColumn 
          field="tag_serial" 
          headerName={translate("Trace+ ID")} 
          valueFormatter={(params) =>params.value}    
          cellClass={'textCapitalize textCap'}
        
         >
      </AgGridColumn> 
      <AgGridColumn 
          field="worker_type" 
          headerName={translate("Type",language)} 
          valueFormatter={(params) =>params.value=='regular'?"Regular Worker":"Daily Worker"}    
          cellClass={'textCapitalize textCap'}
        
         >
      </AgGridColumn> 
      <AgGridColumn 
          field="department" 
          headerName={translate("Department",language)} 
          valueFormatter={(params) =>params.value.toLowerCase()}    
          cellClass={'textCapitalize textCap'}
        
         >
      </AgGridColumn> 
      <AgGridColumn
         field="function"
         headerName={translate("Function",language)}
         
         cellClass={'textCapitalize textCap'}
         valueFormatter={(params) =>params.value?params.value.toLowerCase():'-'}   
        
         >
        </AgGridColumn> 
        <AgGridColumn
         field="check_in"
         headerName={translate("Check-In",language)} 
         cellClass={'textCapitalize textCap'}
         valueFormatter={(params) =>moment(params.value).format('hh:mm A') }   
        
         >
        </AgGridColumn> 
        <AgGridColumn
         field="check_out"
         headerName={translate("Check-Out",language)}         
         cellClass={'textCapitalize textCap'}
         valueFormatter={(params) =>params.value=='-'|| params.value==null ?'-':moment(params.value).format('hh:mm A')}   
        
         >
      </AgGridColumn> 
      <AgGridColumn
         field="status"
         headerName={translate("Status")}
         cellClass={'textCapitalize textCap'}
         valueFormatter={(params) =>params.value=='-'|| params.value==null ?'-':moment(params.value).format('hh:mm A')}   
            width={80}
        cellRenderer= {(params)=>newRanderer(params.value)}

        
         ></AgGridColumn>
  </AgGridReact>
  </div></div>
  
  return arr
}
}

function dateRenderOnboard(params){
  var eGui = document.createElement('div');    
      eGui.innerHTML=`<div class="nameAlign">${(params.value)}<br/>
      <small>Check-In: <strong>${moment(params.data.check_in).format('hh:mm A')}</strong></small>
      <small>Check-Out: <strong>${params.data.check_out!="-"?moment(params.data.check_out).format('hh:mm A'):" - "}</strong></small>
      </div>`;
   return eGui;
}
function nameRenderOnboard(params){
  var eGui = document.createElement('div');    
      eGui.innerHTML=`<div class="nameAlign">${(params.value)}<br/>
        <small>Tag: <strong>${(params.data.tag_serial)}</strong></small>
        <small>ID: <strong>${(params.data.worker_id)}</strong></small>
      </div>`;
   return eGui;
}


function AGTableResp(data){
    

  if(data ){
    
    let widthCal= (widthContainer)/3;
    
    let arr;

    data = data.sort((a,b) => {
      let aMs = moment(a.check_in).format('x')
      let bMs = moment(b.check_in).format('x')
      return  bMs - aMs 
    })

    arr=<div className={"keyAGTable"}><div className="ag-theme-alpine if cell-size-40" style={{height: 60 + ((data.length > 0? data.length:1)*36),maxHeight:500 , width: "calc(100% - 1px)"}}>
      <AgGridReact
          rowHeight={60}
          onCellClicked={onCellClicked}
          enableCellTextSelection={true}
//onCellClicked={onCellClicked}
        
          autoGroupColumnDef={{
              headerName: 'Date',minWidth: 200,field: 'name',
  
          }}
          headerHeight={35}

       defaultColDef={{
        sortable: true,resizable: true,
        minWidth:45, 
        width:widthCal , 
        suppressColumnVirtualisation:true,
        skipHeaderOnAutoSize:true,
        
        
        
      }}           
          //pagination={true}
          rowData={data}
          key={'keyAGTable'}
    
          overlayNoRowsTemplate={
              AGGridEMptyMEssage('')
            }        
       >
        
      <AgGridColumn
          field="check_in"
          headerName={"Date"}
          cellRenderer= {(params)=>dateRenderOnboard(params)}
          cellClass={'textCapitalize textCap'}

      />
        <AgGridColumn
          field="name"
          headerName={"Name"}
          // valueFormatter={(params)=>params.value.toLowerCase()}
          cellRenderer= {(params)=>nameRenderOnboard(params)}
          cellClass={'textCapitalize textCap'}
          
      />
      <AgGridColumn
         field="status"
         headerName="Status"
         
         cellClass={'textCapitalize textCap'}
         valueFormatter={(params) =>params.value=='-'|| params.value==null ?'-':moment(params.value).format('hh:mm A')}   
            width={80}
        cellRenderer= {(params)=>newRanderer(params.value)}

        
         ></AgGridColumn>
  </AgGridReact>
  </div></div>
  
  return arr
}
}


function myFocusFunction(wid,tid){
  console.log((wid!='' && tid!=='') || isModalOpen || !focus)
 if((wid!='' && tid!=='') || isModalOpen || !focus){
  console.log("condition 1",focus);
  }
  else{
    console.log("condition 2");
      let myInput = document.getElementById('idOfInput');
      setTimeout(function() { 
          myInput.focus(); 
      }, 100);
  
  }
}

function ClearHandhandler(){
  SetMessageErrorTag("")
  SetMessageErrorWorker('')
  SetMessageErrorFunction("")
  SetWorkerID('');
  SetSearch('');
  SetTagID('');
  SetFunction('');
  SetSelectedEmp({})
  myFocusFunction('','')
}

function focusOff(){
 SetFocus(false); 
}
function focusTrue(){
 SetFocus(true); 
}
function languageChangeHandler(val){
  console.log("parent Language change detected",val)
  localStorage.setItem('userOnBoardLanguage', val);
  SetLanguage(val);
  focusTrue();
}

function languageChangeBlur(){
  focusTrue();
  console.log("focussssssss")

  let myInput = document.getElementById('idOfInput');
      setTimeout(function() { 
          myInput.focus(); 
      }, 100);
}

useEffect(() => {
  const delayDebounceFn = setTimeout(() => {
    searchHandler(search)

  }, 600)

  return () => clearTimeout(delayDebounceFn)
}, [search])


function modalSearchHandler(){
  if(modalSearch.length>0){
    //SetSearch(modalSearch);
    searchHandler(modalSearch)
    //setIsModalOpen(false);
  }
}

function modalLogoutHandler(){
 
  SetModalLogout(false);
  localStorage.removeItem('userLoginDetailsOnBoard')
  localStorage.removeItem('isLoggedInGatewayOnBoard');
  localStorage.removeItem('tokenAuthTraceOnBoard');
  props.history.push('/login') ;       
  
  }

  useEffect(()=>{
    if(onBoardEmp.length> 0){
      let filter = [...onBoardEmp].filter(item=>
          item.name.toLowerCase().includes(searchCheckIn.toLowerCase().trim()) ||
  
          
          item.worker_id.includes(searchCheckIn.replaceAll(":", "")) 
          ||
          item.tag_serial.toLowerCase().includes(searchCheckIn.toLowerCase().replaceAll(":","")) 
           )

           SetOnBoardEmpDisplay(filter);
    }
  },[searchCheckIn])  

    return (

         <div className="main-content side-content pt-0">

<div className="container-fluid">
    <div className="inner-body">
    <ToastContainer
      autoClose={3000}
      newestOnTop={false}
      closeOnClick
      pauseOnFocusLoss={false}
      pauseOnHover={false}
      width={"500px"}

    />
    <CommonHeading title={<span>{translate('Welcome',language)} <strong>{userDetails.empName}</strong></span>} MainTitle={translate("Worker Onboarding",language)} language={language} focusOff={focusOff} languageChangeHandler={languageChangeHandler} focusTrue={focusTrue} languageChangeBlur={languageChangeBlur} />

{/* logout modal */}
    {/* <Modal
          title="Session Expired"
          open={modalLogout}
          okText="Okay"
          cancelText="Logout"
          onOk={modalLogoutHandler}
          onCancel={()=>SetModalLogout(false)}
         
          className="logoutModal"
          //className="modalStyle"
          // cancelButtonProps={{ disabled: true }}
        >
          <div className='formField formFieldUnderModal'>
           <h2>Logout</h2> 
           <p>Session is expired, please login to continue</p>
    
        </div>
        </Modal> */}


            <Modal
          title={
            <div className='modalHeaderCustom'>
              <img src={WarningSign} className='warning-img'/>
              <h4 style={{color:"#e76173",fontWeight: "bold"}}>{translate('Warning',language)}</h4>
            </div>
            
            }
          open={shiftShortModal}
          okText={translate("Yes, Check-Out",language)}
          cancelText={translate("No, Go Back",language)}
          onOk={checkoutForce}
          onCancel={()=>{SetShiftShortModal(false);SetShortTime("");ClearHandhandler(); 
      let myInput = document.getElementById('idOfInput');
      setTimeout(function() { 
          myInput.focus(); 
      }, 100);


          
          }}
         
          className="logoutModal logoutModalHieght2" 
          //className="modalStyle"
          // cancelButtonProps={{ disabled: true }}
        >
          <div className='formField formFieldUnderModal'>
           <p className='shiftShort'>{shortTime}<small>{translate('Short Time',language)}</small></p>
           <p><strong>English </strong>: "You're trying to check out before your shift ended. Are you sure you want to check out?"</p> 
           <p><strong>Bahasa </strong>: "Anda mencoba melakukan check out sebelum jam kerja anda selesai. Apakah anda yakin ingin melakukan check out? "</p>
    
        </div>
        </Modal>

<Modal
          title={translate("Edit QRCode/ Barcode",language)}
          open={isModalOpen}
          okText={translate("Submit",language)}
          cancelText={translate("Close",language)}
          onOk={modalSearchHandler}
          onCancel={()=>setIsModalOpen(false)}
          okButtonProps={{ disabled: modalSearch.length==0 }}
          // cancelButtonProps={{ disabled: true }}
        >
          <div className='formField formFieldUnderModal'>
            <label>{translate("Please Enter Barcode or QRCode (MAC ID) Serial",language)}</label>
          <input type="text" className='form-control' value={modalSearch}  onChange={(e)=>SetModalSearch(e.target.value)} />
        </div>
        </Modal>
    
      <DashboardStats totalTagDaily={[...onBoardEmp].filter(item=>item.type=="Daily Worker").length} regularTagCheckIN={[...onBoardEmp].filter(item=>item.type=="Regular Worker" ).length} checkout={checkOut} dailyData={dailyScanData} regularData={regularScanData} language={language} />
    
    <div className='onBoardScanArea'>
      <div className='onBoardScanInner'>
        
        <Row className='topAreaScan'>
          <Col lg={3}></Col>
        <Col lg={6}>  
        
        <div className='form-field form-field2'>
        
        <label>{translate('Scan',language)} </label>
        <div className='threething'>
          <input type="text" name="server"
            className='form-control'
            value={search}
            onChange={(e)=>SetSearch(e.target.value)}  onBlur={()=> myFocusFunction(workerID,tagID) } 
            id="idOfInput" autoFocus={true}
            disabled={tagID!="" && workerID!=""}
            placeholder={tagID!="" && workerID!=""?translate("Please CheckIn/Checkout or Clear before new scan",language):""}
            />
          <p className='btn btn-clear' style={{float:'left',color:'#ef5e8c',marginLeft:"10px",marginTop:"25px",fontWeight:"bold"}} onClick={showModal}><Edit/></p>
          <p className='btn btn-clear' style={{float:'left',color:'#ef5e8c',marginLeft:"10px",marginTop:"25px",fontWeight:"bold"}} onClick={ClearHandhandler}><Clear/></p>
        </div>
          
          
       </div>
       </Col>
       <Col lg={3}></Col>
        </Row>  
        <Row className='topAreaScan' style={{paddingTop:"0px",paddingBottom:"30px"}}>
      <Col lg={4} sm={4} xs={12}>
        <div className='innerDiv'>
        <div className='fieldBottom'>
          <div className='fieldList'>{translate('Worker ID',language)} : <strong>{workerID?workerID:""}</strong></div>
          <div className='fieldList'>{translate('Name',language)} : <strong>{workerID && selectedEmp.name? selectedEmp.name:""} </strong></div>
          <div className='fieldList'>{translate('Agency',language)} : <strong>{workerID && selectedEmp.agency?selectedEmp.agency:""} </strong></div>
          <div className='fieldList'>{translate('Worker Type',language)} :  <strong>{workerID && selectedEmp.type?selectedEmp.type:''}</strong></div>
       </div>

        </div>


      </Col>
      <Col lg={4}  sm={4} xs={12}>
      <div className='innerDiv'>
         <div className='fieldBottom'>
            <div className='fieldList'>{translate('Trace+ ID',language)} : <strong>{tagID}</strong></div>
            <div className='fieldList'>{translate('Worker Type',language)} : <strong>{tagID && selectedEmp.type?selectedEmp.type:""} </strong></div>
            <div className='fieldList'>{translate('Tag Type',language)} : <strong>{tagID && selectedEmp.type?"Personal Tag":""}</strong></div>
          </div>
      </div>

      </Col>
      <Col lg={4}  sm={4} xs={12}>
      <div className={selectedEmp.type=="Daily Worker"?'innerDiv boxHighlight':'innerDiv'}>
      <div className='form-field'>

        <label style={{display:"inline-block"}}>{translate('Function',language)} {
        
        
        
        selectedEmp.type=="Daily Worker" &&
         Function=='' && (tagID !='' && workerID !='') && onBoardEmp.findIndex(item=> item.worker_id== selectedEmp.worker_id || item.tag_serial== selectedEmp.tag_serial  )==-1?<span style={{color:"#ff0000"}}>(Required)</span>:""}</label>

          <Select
 
    placeholder={translate("Select Function",language)}
    onChange={(val)=>SetFunction(val)}
    value={Function}
    disabled={
      (selectedEmp && selectedEmp.type=="Regular Worker") ||
       ([...onBoardEmp].findIndex(item=>item.worker_id == selectedEmp.worker_id || item.tag_serial == selectedEmp.tag_serial ) >-1 ||
       tagID =='' || workerID==''
       )} 
    style={{display:"inline-block",marginBottom:"10px"}}

    >
    
 {
 selectedEmp && selectedEmp.type=="Daily Worker"? dailyfunction.map((item)=>{
                                    return <Option value={item.category_id} key={"Department"+item.category_id}>{item.category_name}</Option>
                                })
                              :""}
    </Select>




          {Function=="" && messageErrorFunction?<div className='errorMessage'>{messageErrorFunction}</div>:""}
       </div>
       </div>

       <div className='form-field formfielSubmit'>
          {selectedEmp && selectedEmp.type=="Daily Worker"?
          <input type="submit" className='btn btn-submit' value={nonCheckout.findIndex(item=> item.worker_id== selectedEmp.worker_id || item.tag_serial== selectedEmp.tag_serial ) > -1?translate("Check-Out",language):onBoardEmp.findIndex(item=>(item.worker_id == selectedEmp.worker_id || item.tag_serial== selectedEmp.tag_serial)  && new Date(`${moment(item.check_in).format('YYYY-MM-DD HH:mm')}`) > new Date(`${moment(workingHourDate.start).format('YYYY-MM-DD HH:mm')}`) &&  ( item.check_out!='') )  == -1?translate("Check-In",language):translate("Check-Out",language)} onClick={Addhandler} disabled={workerID=='' || tagID=='' || !apiEnable ||  (Function=="" && onBoardEmp.findIndex(item=>item.worker_id == selectedEmp.worker_id ||item.tag_serial== selectedEmp.tag_serial )== -1)}/>:
          <input type="submit" className='btn btn-submit' value={nonCheckout.findIndex(item=> item.worker_id== selectedEmp.worker_id || item.tag_serial== selectedEmp.tag_serial )> -1? translate("Check-Out",language):onBoardEmp.findIndex(item=> (item.worker_id == selectedEmp.worker_id || item.tag_serial== selectedEmp.tag_serial)  && new Date(`${moment(item.check_in).format('YYYY-MM-DD HH:mm')}`) > new Date(`${moment(workingHourDate.start).format('YYYY-MM-DD HH:mm')}`) && (item.check_out!='' ) )== -1?translate("Check-In",language):translate("Check-Out",language)} onClick={Addhandler} style={{maxWidth:"100%",float:"left"}} disabled={workerID=='' || !apiEnable || tagID==''}/>}      
      </div>
 
      </Col>


    </Row>

    
      </div>
    </div>

    <div className='contentArea' ref={elementRef}>
          <div className='topArea'>
            <Row>
              <Col lg={3} xs={12} style={{paddingRight:0}}>
              <h5 style={{fontSize:"16px"}}>{translate('Worker Check-In / Check-Out history',language)}</h5>
              </Col>
              <Col lg={6} xs={6} style={{textAlign:"center",paddingRight:0}}>
                <div className='datepickerStyle'>
                  <label className='labelDiv'>{translate('Start Date',language)}</label>
                    <DatePicker
                            selected={dates.start}
                            onChange={(date) => handleDate(date, "start")}
                            // dateFormat={"dd MMM, yyyy"}
                            dateFormat={'MMM dd'}
                            isClearable={false}
                          
                            maxDate={moment(dates.end).toDate()}
                            minDate={new Date('2023-01-01')}
                          />
                  </div>
                    <div className='datepickerStyle'>   
                      <label className='labelDiv'>{translate('End Date',language)}</label>
                      <DatePicker
                            selected={dates.end}
                            onChange={(date) => handleDate(date, "end")}
                            // dateFormat={"dd MMM, yyyy"}
                            dateFormat={'MMM dd'}
                            isClearable={false}
                            maxDate={moment().toDate()}
                            minDate={dates.start}
                          />
                </div>
                <div className='noteMessage'>
                  {language=="en"?notMessage:indonesiaTime}
                </div>
              </Col>
              {/* <Col lg={2} style={{textAlign:"right"}}>
                  <Export data={onBoardEmp} start_date={dates.start} end_date={dates.end} />
              </Col> */}
              <Col lg={3} xs={6} style={{textAlign:"center",paddingLeft:0}}>
                <input className='form-control formLeftSPace' type="text" placeholder={translate('Search...',language)} value={searchCheckIn} onFocus={()=>{myFocusFunction('dasda',"asddad");SetFocus(false);}} onChange={(e)=>{SetFocus(false);SetSearchCheckIn(e.target.value)}} onBlur={()=>setTimeout(function(){SetFocus(true)},100) } style={{maxWidth:"60%",float:"left"}}  />
                <Export data={onBoardEmp} start_date={dates.start} end_date={dates.end} style={{maxWidth:"70%",display:"inline-block"}}  />
              </Col>
            </Row>
          </div>
          
              <div className='tableArea' ref={elementRef}>
                {window.innerWidth > 650? AGTable(onBoardEmpDisplay):AGTableResp(onBoardEmpDisplay)}
              </div>
              

        
    </div>

        </div>
        </div>



    </div>
              )
}

const mapStateToProps = (state) => ({
    language: state.dashboard.selectedLangaugeValue
})

export default connect(mapStateToProps, {  })(withRouter(Dashboard))
